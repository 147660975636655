<template>
  <!-- 饼图 -->
  <div :id="id" class="chartsBox"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    id: {
      type: String,
      default: "chartDom",
    },
    option: {
      type: Object,
      default: () => { },
      // {
      // id:"",
      // series: [
      //     { value: 40, name: "rose 1" },
      //     { value: 38, name: "rose 2" },
      //     { value: 32, name: "rose 3" },
      //     { value: 30, name: "rose 4" },
      //   ],
      //  content:{},
      //  setSeries:{
      //     roseType: "area", //南丁格尔玫瑰图 radius
      //     radius: ['20%', '50%'],//[内半径，外半径] 饼图的半径
      //     itemStyle: {
      //       borderRadius: 8,
      //     },
      //  }
      //};
    },
  },
  data() {
    return {
      chart: null,
      chartDom: null,
      defaultData: {
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
        tooltip: {
          trigger: 'item'
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            type: "pie",
            // itemStyle: {
            //   borderRadius: 8,
            // },
            data: [],
          },
        ],
      },
      timer: null,
      currentIndex: -1,
      isAnimation: false,//是否开启自动显示
    };
  },
  watch: {
    data() {
      this.initOption();
    },
  },
  mounted: function () {
    this.initOption();
  },
  methods: {
    handle() {
      this.defaultData.color = [];
      this.defaultData.color = this.option.color;
      this.defaultData.series[0] = Object.assign(this.defaultData.series[0], this.option.setSeries);
      if (this.option.content != undefined) {
        this.defaultData = Object.assign(this.defaultData, this.option.content);
      }
      if (this.option.isAnimation != undefined) {
        this.isAnimation = this.option.isAnimation;
      }
      this.defaultData.series[0].data = this.option.series;
      return this.defaultData;
    },
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById(this.id);
        if( this.chartDom==null) return ;
        this.chart = echarts.init(this.chartDom);
        if (this.option) {
          this.chart.setOption(this.handle());
        }
        if (this.isAnimation) {
          this.chart.getZr().on('mousemove', ()=>{
            this.closeTooltip()
          })
          this.chart.getZr().on('mouseout', ()=>{
            this.startTooltioLoop()
          })
          this.startTooltioLoop();
        }
      });
    },
    switchToooltip() {
      this.chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.currentIndex = (this.currentIndex + 1) % this.defaultData.series[0].data.length;
      this.chart.dispatchAction({
        type: 'highlinght',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
    },
    startTooltioLoop() {
      this.timer = setInterval(() => {
        this.switchToooltip();
      }, 2500);
    },
    closeTooltip() {
      clearInterval(this.timer);
      this.timer = undefined;
    },
  },
  beforeDestroy() {
    this.closeTooltip();
  },

};
</script>
<style scoped>
.chartsBox {
  width: 100%;
  height: 100%;
}
</style>

